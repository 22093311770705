html {
    scroll-behavior: smooth;
    font-size: clamp(10px, 2.5vw, 16px);
    overflow: scroll;
}

body, .App {
    width: 100%;
}
/* ---- General CSS classes ----*/

.txt-is-white{
    color: white;
}


.bg-primary-dark{
    background-color: #140078;
}

.bg-primary{
    background-color: #512DA8;
}


.font-size-2 {
    font-size: 2rem;
}

.is-inline-flex{
    display: inline-flex;
}

.is-inline-block{
    display: inline-block;
}

/* ---- Webpage specific ---- */

h1{
   font-size: 3rem; 
}

h2{
    font-size: 2rem;
}

.link{
    color: white;
    font-size: max(13px,1.1rem);
}

.link:visited{
    color: white;
}
/* ---- NavBar styling ---- */

.navbar{
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 30;
}

.navbar-menu{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-item, .download-item {
    cursor: pointer;
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
}
    .navbar-item:hover, 
    .download-item:hover{
        background-color: #0c0048;
    }
    .navbar-item:focus, 
    .download-item:focus{
        background-color: #0c0048;
        border: none;
    }

.navbar-item{
    padding: 16px 8px;
}


.download-item{
    margin-left: auto;
    margin-right: 8px;

    border-radius: 2px;
    border-color: white;
    border-style: solid;

    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 17px;
    padding-right: 17px;
}

@media screen and (min-width: 1024px){
    .navbar-burger {
        display: none;
    }
}

/* ---- Landing Page ---- */

.landing-page{
    background-image: url('../imgs/YellowStars.png');
    background-color: black;
    background-attachment: fixed;

    height: 100vh;
    width: 100%;

    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.section-hero{
    padding: 6rem 0rem;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
}

/* ---- About Me ---- */

#about{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

#about > img {
    border-radius: 50%;
    display: inline-flex;
    width: 16rem;
    height: 16rem;
}

.about-section{
    display: inline;
}

.about-section h2 {
    margin: 1rem;
}

.about-body{
    display: inline-flex;
    flex-direction: column;
    
    max-width: 968px;
    width: 70%;
    background-color: white;
    border-radius: 1rem;

    padding: 1.5rem;
}

.about-body > p {
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    text-align: left;
    font-size: max(16px, 1.1rem);
}

/* ----- My Stack ----- */
#my-stack{
    background-color: #E1E2E1;
}

.hero-header {
    font-size: 4rem;
    text-decoration: underline;
}

.stack-list{
    text-align: left;
    display: flex;
    flex-direction: column;
    padding-left: 3.5rem;
}

.stack-list > h3 {
    font-size: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/* ----- Projects ----- */

.projects-content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.project-card{
    display: inline-block;
    background-color: white;
    border-radius: 0.5rem;
    max-width: 380px;
    overflow: hidden;
    position: relative;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;;
    text-align: left;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 2rem;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}

.card-content {
    background-color: transparent;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1rem;
    height: 17rem;
    min-height: 180px;
}

.card-header{
    font-size: 2rem;
}

.card-body{
    font-size: max(1.2rem, 16px);
}

.card-img{
    max-width: 100%;
    height: 285px;
}

.card-footer {
    border-top: 1px solid #ededed;
    align-items: stretch;
    display: flex; 
}
  
.card-footer-item {
    align-items: center;
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    padding: max(0.75rem, 10px);
    font-size: max(14px, 1rem);
    color: #140078;
    text-decoration: none;
    font-weight: bold;
}

.card-footer-item:not(:last-child) {
    border-right: 1px solid #ededed; 
}

/* ----- Projects ----- */
#footer {
    background-color: #F5F5F6;
    padding: 3.5rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

#footer > *{
    padding-bottom: 0.5rem;
}

.contact-card{
    text-align: left;
}

.contact-card > h1{
    margin: 0px;
}

.contact-card:not(:last-child) {
    margin-bottom: 0.5rem;
}

.contact-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .contact-item > img {
    width: 32px;
    padding: 0.5rem 0.25rem;
  }


.icon{
    height: 32px;
    padding: 0.5rem 0.25rem;
}

.social-media-bar a:not(:last-child){
    margin-right: 1.5rem;
}

.content-column{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;;
}


.content-column > *:not(:last-child){
    margin: 0px;
    padding-bottom: 0.5rem;
}

.content-column > h1 {
    text-decoration: underline;
}

@media screen and (min-width: 790px){
    .content-column{
        margin-left: 10px;
    }
}


.content-link{
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
}

.content-link:visited{
    color: black;
}