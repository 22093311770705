@font-face {
  font-family: 'sabofilled';
  src: url('./fonts/sabo-filled-webfont.woff2') format('woff2'),
       url('./fonts/sabo-filled-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


body {
  margin: 0;
  font-family: sabofilled, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
